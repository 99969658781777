.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  --color-primary: #EBF2EE;
  --color-secondary: #5F734C;
  --color-terciary: #7EBF8E;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/index.css */
body {
  background-color: #fff;
  color: #121212;
  font-family: 'Poppins', sans-serif;
}

a {
  color: #1e88e5;
}

h1, h2, h3, h4, h5, h6 {
  color: #121212;
}

/* src/App.css */
.container {
  max-width: 800px;
}

pre {
  overflow: auto;
  max-height: 200px;
}

a.text-white:hover {
  color: #1e88e5;
  text-decoration: none;
}

button.ant-btn {
  width: 200px;
}

.sidebar {
  height: 100vh;
  overflow-y: auto;
}
.disabled{
  opacity: .75;
}
.code-output{
  height: 17vh;
}
.float-right{
  float:right
}

.banner-agenda {
  height: auto !important;
  padding-top: 30px;
  padding-bottom: 10px;
}

.banner-agenda img {
  max-width: 12vw
}

.banner-agenda a {
  color:white  !important
}
p{
  font-size: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
}
.topics li{
  font-size: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .5rem;
}

.playground-link{
  right: 50%;
  transform: translate(50%, -50%)
}

.home-banner{
  height: 75vh;
}

/*+++++++++++++++++++*/
@media (max-width: 768px) {

  .banner-agenda {
    height: auto !important;
    padding-top: 20px;
    padding-bottom: 20px;
    color:white
  }

  .banner-agenda img {
    max-width: 50vw !important;
  }

  .code-output{
    height: 10vh;
  }

  .playground-link{
    right: 0%;
    transform: translate(0%, -30%)
  }

  .home-banner{
    height: 50vh;
  }

}